import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFilterCount } from 'components/shared/AppTable/components/TableFilters/TableFilters';
import {
  CustomSchema, IObject, IPropertyForm, Schema,
} from 'interfaces/IObject';

export interface IObjectTypesState {
  objects: IObject[];
  totalCount: number | null;
  filters: IFilterCount | null;
  isObjectsLoading: boolean;
  triggerObjects: object;
  objectTypeDetails: IObject | null;
  isShowAddPropertyModal: boolean;
  isShowEditPropertyModal: boolean;
  properties: Schema;
  atomyxProperties: CustomSchema | null;
  editedProperty: IPropertyForm | null;
  privateKey: string | null;
}

export const initialState: IObjectTypesState = {
  objects: [],
  totalCount: null,
  filters: null,
  isObjectsLoading: false,
  triggerObjects: {},
  objectTypeDetails: null,
  isShowAddPropertyModal: false,
  isShowEditPropertyModal: false,
  properties: {},
  atomyxProperties: null,
  editedProperty: null,
  privateKey: null,
};

export const objectTypesSlice = createSlice({
  name: 'objectTypes',
  initialState,
  reducers: {
    setObjects(state, action: PayloadAction<IObject[]>) {
      state.objects = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setFilters(state, action: PayloadAction<IFilterCount | null>) {
      state.filters = action.payload;
    },
    setIsObjectsLoading(state, action: PayloadAction<boolean>) {
      state.isObjectsLoading = action.payload;
    },
    setIsShowEditPropertyModal(state, action: PayloadAction<boolean>) {
      state.isShowEditPropertyModal = action.payload;
    },
    setIsShowAddPropertyModal(state, action: PayloadAction<boolean>) {
      state.isShowAddPropertyModal = action.payload;
    },
    setObjectTypeDetails(state, action: PayloadAction<IObject | null>) {
      state.objectTypeDetails = action.payload;
    },
    setProperties(state, action: PayloadAction<Schema>) {
      state.properties = action.payload;
    },
    setAtomyxProperties(state, action: PayloadAction<CustomSchema | null>) {
      state.atomyxProperties = action.payload;
    },
    setEditedProperty(state, action: PayloadAction<IPropertyForm | null>) {
      state.editedProperty = action.payload;
    },
    setPrivateKey(state, action: PayloadAction<string | null>) {
      state.privateKey = action.payload;
    },
    setTriggerObjects(state) {
      state.triggerObjects = {};
    },
    resetObjectTypesState: () => initialState,
  },
});

export const {
  setObjects,
  setIsObjectsLoading,
  setTriggerObjects,
  setTotalCount,
  setFilters,
  setObjectTypeDetails,
  setIsShowAddPropertyModal,
  setIsShowEditPropertyModal,
  setProperties,
  setEditedProperty,
  setAtomyxProperties,
  resetObjectTypesState,
  setPrivateKey,
} = objectTypesSlice.actions;

export default objectTypesSlice.reducer;
